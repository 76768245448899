import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';                      // 引入element-ui
import 'element-ui/lib/theme-chalk/index.css';           // element-ui的css样式要单独引入
import '@/assets/styles/common.css' // global css
import '@/utils/axios'
import VueCookies from 'vue-cookies'
import jquery from 'jquery'
// import SvgIcon from '@/components/SvgIcon'// svg component
import * as VueGoogleMaps from 'vue2-google-maps'
// // register globally
// Vue.component('svg-icon', SvgIcon)
// import * as VueGoogleMaps from 'vue2-google-maps'
// Vue.config.productionTip = false
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyBiAL4DKN6JZGbG93fzNliMCMD6Sg9llo8', // 此处填入谷歌地图申请的key
//     libraries: 'places'
//   }
// })
// ======多语言======
//import locale from 'element-ui/lib/locale/lang/en'
import i18n from '@/i18n'
//import VueI18n from 'vue-i18n' // 引入国际化的插件包
//import elementEN from 'element-ui/lib/locale/lang/en' // 引入饿了么的英文包
//import elementZH from 'element-ui/lib/locale/lang/zh-CN' // 引入饿了么的中文包

//Vue.use(VueI18n) 
import Quagga from 'quagga'
Vue.prototype.$Quagga = Quagga 
//Vue.use(ElementUI, { locale })
Vue.use(VueCookies)

Vue.use(ElementUI);   // 这种方式引入了ElementUI中所有的组件
// 全局组件挂载
import Footer from '@/components/Footer'
Vue.component('Footer', Footer)
Vue.config.productionTip = false
Vue.prototype.msgSuccess = function (msg) {
  this.$message({ showClose: true, message: msg, type: 'success' })
}

Vue.prototype.msgError = function (msg) {

  this.$message({ showClose: true, message: msg, type: 'error' })
}
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBiAL4DKN6JZGbG93fzNliMCMD6Sg9llo8',
    libraries: 'places',
  }
});
Vue.prototype.$ = jquery
Vue.prototype.$Cookies = VueCookies
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
