import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    languageType:'en'
  },
  getters: {
    getLanguageType:state=>state.setLanguageType
  },
  mutations: {
    setLanguageType(state,languageType){
      state.languageType = languageType
    }
  },
  actions: {
  },
  modules: {
  }
})
