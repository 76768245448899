export default {
    //首页  index
    index: {
        about: 'About',
        product: 'Product',
        logIn: 'Log In',
        logOut: 'Log Out',
        model: 'Inventory Details',
        // 请输入光缆盘号
        ts: 'Please enter the cable reel number',
        instructionVideo:'user instruction video'
    },
    //登录页
    login: {
        username: 'username',
        password: "password",
        // 验证码
        imgcode: 'CAPTCHA',
        logging: 'Logging In',
    },
    installer: {
        modelDesc: ''
    },
    // form表单提交
    form: {
        sure: 'OK',
        cancel: 'Cancel',
        // 评论
        commentLabel: 'Comment',
        // 输入评论内容
        commentPlaceholder: 'Comment',
        // 起始结束米标图片
        startEndPic: 'Picture of length marking',
        // 施工图片
        SmartReelOTDR: 'Picture of SmartReel OTDR test result(Optional)',
        footageprint: 'Length marking',
        email: 'Email',
        emailPlaceholder: 'Email',
        GPS: 'GPS(Optional)',
        pointtype: 'Installation stage',
        operator: 'Operator',
        // 请输入你的名字 Please enter your name
        operatorPlaceholder: 'Your name',
        constructor: 'Constructor',
        // 请输入公司名 Please enter your company name
        constructorPlaceholder: 'Company name',
        installationReport: 'Installation Report',
        // 上报endpoint
        reportDetail: 'Report End Point Detail',
        startPoint: 'Start Point',
        endPoint: 'End Point',
        // 米标示例
        eg: 'eg.10006',
        skip:'skip the prompt'
    },
    // 表单校验
    formRule: {
        // 请输入公司名称
        constructor_name: ' Please enter your company name',
        // 请选择类型
        pointtype: ' Please select installation stage',
        // 请输入名字
        operator: ' Please enter your name',
        // 请输入米标
        footageprint: ' Please enter the length marking',
        // 请输入正整数
        positiveInteger: ' Please enter a positive integer',
        // 邮箱不能为空
        email: ' Please enter your email',
        // 请输入正确的邮箱格式Correct email format
        format: ' Please enter a valid email address'

    },
    // googlemap
    googlemap: {
        // 搜索并添加地点
        searchAndAdd: 'Search and add a pin',
        // （注：此为添加地点按钮）
        add: 'Add',
        // 搜索提示语
        placeholder:'Please enter the address',
        pointLabel:'Latitude and longitude',
        latitude:'latitude',
        longitude:'longitude',
        alert:'Please enter longitude and latitude'
        
    },
    // allCable 所有模型列表
    allCable: {
        // 选择模型
        choose: 'Please choose',
        // 盘号
        reelNumber: 'Reel number',
        // 输入盘号（与reelNumber字段搭配查询）
        input: 'Please enter',
        time: 'Time',
        statDate: 'Start Date',
        endDate: 'End Date',
        // 剩余长度
        remainingLength: 'Remaining Length',
        search: 'Search',
        clear: 'Clear',
        // 型号名称
        cableModel: 'Cable model',
        // 最后一次施工时间
        lastInstallation: 'Last installation',
        remark: 'Remark',
        installationDetail: 'Installation detail',
        // 安装详情
        detail: 'Detail',
        diskName: 'Cable Name',
    },
    // dashbroad
    dashbroad: {
        name: 'Name',
        picture: 'Picture',
        // meterMarker:'Length marker',
        gps: 'GPS',
        // warrantyDeadline:'Warranty expiration date',
        //    添加时间
        // createTime: 'Created time',
        //    模型描述
        modelDesc: '',
        // dataSheet:'Data Sheet',
        // ig:'IG', Installation guideline
        more: 'More',
        comments: 'Comments',
        // 加载更多
        loadMore: 'Load more',
    },
    // detail
    detail: {
        // videoPlayer:'YouTube video player',
        //  （注：此为安装上上报的按钮）
        warrantyRegister: 'Warranty Register',
        noData: 'No data',
    },
    // table盘号列表
    table: {
        // 剩余长度
        residuelength: 'Remaining length',
        // 选择施工人
        selectConstructor: 'Select constructor',
        //    米标
        footage: 'Footage',
        endPoint: 'End point',
        // 质保年限
        warrantyperiod: 'Warranty period',
        // 质保截止时间
        expireDate: 'Expiration date',
        // 更多模型信息
        moreCableInfo: 'More cable information  ',
        // 原始长度
        originallength: 'Original length'
    },
    // tableMap
    tableMap: {
        goBack: 'go back',
    },
    // ======其他内容======

    // 备案
    filings: ' 2023 Copyright Paragon Navigator lnc.',
    // 盘号失效
    traynumTips: 'The reel number has expired',
    // 注册协议提示
    mianzeTips: 'Do you want to read the registration agreement?',
    // 注册协议
    RegistrationAgreement: 'Registration Agreement',
    // 去上报endpoint
    nofinishTitle: " Report End Point Detail",

    //  注：以下四个字段是用于注册成功之后的提示   复制临时页的内容
    oneTit:'Registration Pass',
    twoTit:'Notification',
    one:'Reel number :',
    two:'has been registered and is covered by the',
    three:'year warranty until',
    passTips:'Please note it is the responsibility of the registered company to ensure that all fiber optic cable under warranty has been handled and installed within normal installation guidelines and industry standards.',
    passTipsStart:'You have successfully filled out the installation report for the start point. Please fill out the installation report for the end point once the installation is complete.',
    // 引导提示
    step: {
        got_it:'Got it',
        index_01:'Enter a reel number to check information specific to that cable and initiate a warranty registration process.',
        index_02:'Select a cable from the Product List to check the corresponding information about that cable.',
        index_03:'Watch the user instruction video to learn how to use the platform.',
        index_04:'Log in to access the distributor exclusive view.',
        installer_01:'Downloadable files include Datasheet, Installation Guidelines and Transportation and Handling Guidelines.',
        installer_02:'Watch the fiber access video to learn how to perform end span & mid span access.',
        installer_03:'Click on Registration Agreement to read it. Make sure to tick the box before proceeding.',
        installer_04:'Click Warranty Register to fill the Installation Report and initiate your extended warranty.',
        form_a_01:'* Indicates the field is mandatory. Start the Installation Report by filling in the name of your company and your name.',
        form_a_02:'Every Installation Report consists of two parts, the start point and end point. The selection of installation stage is automatic.',
        form_a_03:'Check the length marking on the cable to verify the cable length at the start point.',
        form_a_04:'Upload an image containing length marking on the cable, click on the image icon to see an example of length marking.',
        form_a_05:'GPS feature supports both automatic pinpointing and manual inputting address or coordinates.',
        form_a_06:'Upload an image of OTDR test results (quick cable length verification via SmartReel™).',
        form_a_07:'Please feel free to leave a comment and tell us what you think of this fiber cable.',
        form_a_08:'Click OK once the fields are filled, confirm and continue to filling in end point half of the Installation Report.',
        form_b_01:'Confirm the correct length marking of start point and start to fill in end point half of the Installation Report.',
        form_b_02:'Fill in your contact email address and other fields.',
        form_b_03:'Click OK to submit the Installation Report. The registration process is now complete and the extended warranty will initiate when you see the Registration Pass.',
        allCable_01:'Input article number or cable name and other information to search for a filtered result.',
        allCable_02:'Click Detail to check the corresponding Installation Report(s).',
        table_01:'Select a constructor to filter the Installation Report(s).',
        table_02:'Click on the GPS logo to check precise locations of selected point.',
        table_03:'Click More cable information to return to the cable product page.',
        table_04:'Click on the account icon to log out or return to inventory details page.',
    },
}
