<template>
  <div class="language">
    <div class="language-pc">
      <span
        :class="curIndex == 'en' ? 'select-index' : ''"
        @click="changeLanguage('en')"
        >EN</span
      >
      &nbsp;&nbsp; | &nbsp;&nbsp;
      <span
        :class="curIndex == 'es' ? 'select-index' : ''"
        @click="changeLanguage('es')"
        >ES</span
      >
    </div>
    <div class="mobile-div">
      <el-dropdown @command="changeLanguage">
        <span class="el-dropdown-link">
          {{ curIndex == "en" ? "EN" : "ES"
          }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="en">EN</el-dropdown-item>
          <el-dropdown-item command="es">ES</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { getSessionStorageData, setSessionStorage } from "@/utils/auth";
import store from "@/store";
export default {
  name: "Language",
  data() {
    return {
      curIndex: "en",
    };
  },
  mounted() {
    let cur = getSessionStorageData("select_langue");
    if (!!cur) {
      this.curIndex = cur;
      this.$i18n.locale = cur;
      store.commit('setLanguageType',cur)
    }
  },
  watch: {
    '$store.state.languageType' (newValue, oldValue) {
      this.curIndex=newValue
    }
  },
  methods: {
    changeLanguage(lang) {
      this.curIndex = lang;
      this.$i18n.locale = lang; // 设置给本地的i18n插件
      setSessionStorage("select_langue", lang);
      store.commit('setLanguageType',lang)
    },
  },
};
</script>
<style scoped>
.language {
  display: inline-block;
  margin-right: 3rem;
  > span {
    cursor: pointer;
  }
}
.select-index {
  color: #21267c;
}
.language-pc {
  display: inline-block;
}
.mobile-div {
  display: none;
}
.mobile-div .el-dropdown {
  color: #fff;
}
.language-pc > span {
  cursor: pointer;
}
@media only screen and (max-width: 720px) {
  .language-pc {
    display: none;
  }
  .mobile-div {
    display: block;
  }
}
.el-dropdown-menu__item {
  font-family: Helvetica, helvetica, Arial, sans-serif;
}
</style>