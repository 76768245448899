<template>
  <div class="footer">
    <div class="footer-text">&copy;{{$t("filings")}}</div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data () {
    return {
      filings:' 2023 Copyright Paragon Navigator lnc.'
    }
  }
};
</script>

<style>
.footer {
  width: 100%;
  /* position: fixed; */
  /* bottom: 0; */
  /* z-index: 99; */
}

.footer-text {
  height: 40px;
  line-height: 40px;
  background: #000;
  color: #fff;
  text-align: center;
}
</style>