export default {
    //首页  index
    index: {
        about: 'SOBRE',
        product: 'PRODUCTO',
        logIn: 'Acceso',
        logOut: 'Cerrar sesión',
        model: 'Inventory Details',
        // 请输入光缆盘号
        ts: 'Por favor, introduzca el número de carrete de cable',
        instructionVideo:'vídeo de instrucciones para el usuario'
    },
    //登录页
    login: {
        username: 'nombre de usuario',
        password: "contraseña",
        // 验证码
        imgcode: 'CAPTCHA',
        logging: 'Iniciando sesión',
    },
    installer: {
        modelDesc: ''
    },
    // form表单提交
    form: {
        sure: 'OK',
        cancel: 'Cancelar',
        // 评论
        commentLabel: 'Comentario',
        // 输入评论内容
        commentPlaceholder: 'Comentario',
        // 起始结束米标图片
        startEndPic: 'Imagen de marcado de longitud',
        // 施工图片
        SmartReelOTDR: 'imagen del resultado de la prueba SmartReel OTDR(Opcional)',
        footageprint: 'Marcado de longitud',
        email: 'Email',
        emailPlaceholder: 'Email',
        GPS: 'GPS(Opcional)',
        pointtype: 'Etapa de instalación',
        operator: 'Operador',
        // 请输入你的名字 Please enter your name
        operatorPlaceholder: 'Su nombre',
        constructor: 'Constructor',
        // 请输入公司名 Please enter your company name
        constructorPlaceholder: 'Nombre de empresa',
        installationReport: 'Informe de instalación',
        // 上报endpoint
        reportDetail: 'Detalle del punto final del informe',
        startPoint: 'Punto de partida',
        endPoint: 'punto final',
        // 米标示例
        eg: 'por ejemplo, 10006',
        skip:'omitir el tutorial'
    },
    // 表单校验
    formRule: {
        // 请输入公司名称
        constructor_name: ' Por favor ingrese el nombre de su empresa',
        // 请选择类型
        pointtype: ' Seleccione la etapa de instalación',
        // 请输入名字
        operator: ' por favor, escriba su nombre',
        // 请输入米标
        footageprint: ' Por favor, introduzca la marca de longitud',
        // 请输入正整数
        positiveInteger: ' Por favor ingrese un número entero positivo',
        // 邮箱不能为空
        email: ' Por favor introduzca su correo electrónico',
        // 请输入正确的邮箱格式Correct email format
        format: ' Por favor, introduce una dirección de correo electrónico válida'

    },
    // googlemap
    googlemap: {
        // 搜索并添加地点
        searchAndAdd: 'Buscar y agregar un pin',
        // （注：此为添加地点按钮）
        add: 'Agregar',
        // 搜索提示语
        placeholder:'Por favor, ingrese la dirección',
        pointLabel:'Longitud y latitud',
        latitude:'Latitud',
        longitude:'Longitud',
        alert:'Por favor ingrese longitud y latitud'
    },
    // allCable 所有模型列表
    allCable: {
        // 选择模型
        choose: 'Por favor elige',
        // 盘号
        reelNumber: 'Número de carrete',
        // 输入盘号（与reelNumber字段搭配查询）
        input: 'Por favor escribe',
        time: 'Tiempo',
        statDate: 'Fecha de inicio',
        endDate: 'Fecha final',
        // 剩余长度
        remainingLength: 'Longitud restante',
        search: 'Buscar',
        clear: 'Claro',
        // 型号名称
        cableModel: 'Modelo de cable',
        // 最后一次施工时间
        lastInstallation: 'Última instalación',
        remark: 'Observación',
        installationDetail: 'Detalle de instalación',
        // 安装详情
        detail: 'Detalle',
        diskName: 'Nombre del cable',
    },
    // dashbroad
    dashbroad: {
        name: 'Nombre',
        picture: 'Imagen',
        // meterMarker:'Marcador de longitud',
        gps: 'GPS',
        // warrantyDeadline:'fecha de expiración de la garantía',
        //    添加时间
        // createTime: 'tiempo creado',
        //    模型描述
        modelDesc: '',
        // dataSheet:'Ficha de datos',
        // ig:'IG', Installation guideline
        more: 'Más',
        comments: 'Comentarios',
        // 加载更多
        loadMore: 'Carga más',
    },
    // detail
    detail: {
        // videoPlayer:'reproductor de videos de youtube',
        //  （注：此为安装上上报的按钮）
        warrantyRegister: 'Registro de garantía',
        noData: 'Sin datos',
    },
    // table盘号列表
    table: {
        // 剩余长度
        residuelength: 'Longitud restante',
        // 选择施工人
        selectConstructor: 'Seleccionar constructor',
        //    米标
        footage: 'Footage',
        endPoint: 'punto final',
        // 质保年限
        warrantyperiod: 'Período de garantía',
        // 质保截止时间
        expireDate: 'Fecha de caducidad',
        // 更多模型信息
        moreCableInfo: 'Más información de cables  ',
        // 原始长度
        originallength: 'Longitud original'
    },
    // tableMap
    tableMap: {
        goBack: 'regresa',
    },
    // ======其他内容======

    // 备案
    filings: ' 2023 Derechos de autor Paragon Navigator Inc.',
    // 盘号失效
    traynumTips: 'El número de carrete ha caducado',
    // 注册协议提示
    mianzeTips: '¿Quieres leer el acuerdo de registro?',
    // 注册协议
    RegistrationAgreement: 'Acuerdo de registro',
    // 去上报endpoint
    nofinishTitle: " Detalle del punto final del informe",

    //  注：以下四个字段是用于注册成功之后的提示   复制临时页的内容
    oneTit:'Pase de registro',
    twoTit:'Notificación',
    one:'Número de carrete :',
    two:'ha sido registrado y está cubierto por la garantía de',
    three:'años hasta el',
    passTips:'Tenga en cuenta que es responsabilidad de la empresa registrada asegurarse de que todo el cable de fibra óptica bajo garantía haya sido manipulado e instalado dentro de las pautas de instalación normales y los estándares de la industria.',
    passTipsStart:'Ha completado con éxito el informe de instalación para el punto de inicio. Complete el informe de instalación para el punto final una vez que se complete la instalación.',
    // 引导提示
    step: {
        got_it:'entiendo',
        index_01:'Ingrese un número de carrete para verificar la información específica de ese cable e inicie un proceso de registro de garantía.',
        index_02:'Seleccione un cable de la Lista de productos para comprobar la información correspondiente sobre ese cable.',
        index_03:'Mire el video de instrucciones para el usuario para aprender a usar la plataforma.',
        index_04:'Inicie sesión para acceder a la vista exclusiva del distribuidor.',
        installer_01:'Los archivos descargables incluyen la hoja de datos, las pautas de instalación y las pautas de transporte y manipulación.',
        installer_02:'Mire el video de acceso a la fibra para aprender cómo realizar el acceso al tramo final y al tramo medio.',
        installer_03:'Haga clic en Acuerdo de registro para leerlo. Asegúrese de marcar la casilla antes de continuar.',
        installer_04:'Haga clic en Registro de garantía para completar el Informe de instalación e iniciar su garantía extendida.',
        form_a_01:'* Indica que el campo es obligatorio. Comience el Informe de instalación completando el nombre de su empresa y su nombre.',
        form_a_02:'Todo informe de instalación consta de dos partes, el punto de inicio y el punto final. La selección de la etapa de instalación es automática.',
        form_a_03:'Verifique la marca de longitud en el cable para verificar la longitud del cable en el punto de inicio.',
        form_a_04:'Cargue una imagen que contenga la marca de longitud en el cable, haga clic en el icono de la imagen para ver un ejemplo de marca de longitud.',
        form_a_05:'La función GPS admite tanto la localización automática como la introducción manual de direcciones o coordenadas.',
        form_a_06:'Cargue una imagen de los resultados de la prueba OTDR (verificación rápida de la longitud del cable a través de SmartReel™).',
        form_a_07:'No dude en dejar un comentario y decirnos qué piensa de este cable de fibra.',
        form_a_08:'Haga clic en Aceptar una vez que se completen los campos, confirme y continúe completando la mitad del punto final del Informe de instalación.',
        form_b_01:'Confirme la marca de longitud correcta del punto de inicio y comience a completar la mitad del punto final del Informe de instalación.',
        form_b_02:'Complete su dirección de correo electrónico de contacto y otros campos.',
        form_b_03:'Haga clic en Aceptar para enviar el Informe de instalación. El proceso de registro ahora está completo y la garantía extendida se iniciará cuando vea el Pase de registro.',
        allCable_01:'Input article number or cable name and other information to search for a filtered result.',
        allCable_02:'Click Detail to check the corresponding Installation Report(s).',
        table_01:'Select a constructor to filter the Installation Report(s).',
        table_02:'Click on the GPS logo to check precise locations of selected point.',
        table_03:'Click More cable information to return to the cable product page.',
        table_04:'Click on the account icon to log out or return to inventory details page.',
    },
}
