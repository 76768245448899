export function setSessionStorage(name,value) {
  sessionStorage.setItem(name, value);
}

export function removeSessionStorage(name) {
  sessionStorage.removeItem(name);
}

export function getMenus() {
  return getSessionStorageData('menus');
}

export function getUser() {
  return getSessionStorageData('user') || {homePageUrl:'/'+projectName+'/login'};
}

export function getSessionStorageData(key) {
    const jsonStr = sessionStorage.getItem(key);
    let jsonObj;
    if (jsonStr) {
      try {
        jsonObj = JSON.parse(jsonStr);
      } catch (e) {
        jsonObj = jsonStr;
      }
    }
    return jsonObj;
}
