<template >
  <div id="searchPage">
    <el-row style="" class="head">
      <el-col :xs="24" :sm="24" :md="19" :lg="18" :xl="17" class="head-box">
        <img :src="url_left" alt="" class="img-box" />
        <!-- 关于型号 -->
        <el-col class="about" :xs="7" :sm="5" :md="7" :lg="6" :xl="6">
          <el-dropdown @command="handleModelCommand" class="product-drop" :class="{step_1:isFirst==1&&status!=='login'&&step==2}">
            <span class="el-dropdown-link cursor">
              {{ $t("index.product") }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="item.id"
                v-for="item in modelList"
                :key="item.id"
                >{{ item.name }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <div class="stepArea_2" v-if="isFirst==1&&status!=='login'&&step==2">
            <div class="line" style="height: 10vh;margin:0px 0 0 50%;"><div class="border"></div></div>
            <div class="line" style="height:7px;margin:0px 0 .6vh 50%;"><div class="spot"></div></div>
            <div class="stepInfo">
              <div class="txt">{{ $t("step.index_02") }}</div>
            </div>        
            <div class="stepInfo">
              <span class="btn" @click="stepNext">{{ $t("step.got_it") }}</span>
            </div>
          </div>
        </el-col>        
        <div class="select-div">
          <Language></Language>
          <span v-if="status == 'login'" class="logout cursor">
            <span class="logout-icon" @click="handleCommand('b')">
              <i class="el-icon-user"></i>
            </span>
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                {{ username }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-back" command="a">{{
                  $t("index.logOut")
                }}</el-dropdown-item>
                <el-dropdown-item icon="el-icon-back" command="b">{{
                  $t("index.model")
                }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
          <span @click="login" v-else class="login cursor" :class="{step_4:isFirst==1&&status!=='login'&&step==4}">
            <i class="el-icon-user"></i>
            {{ $t("index.logIn") }}
          </span>

          <div class="stepArea_4" v-if="isFirst==1&&status!=='login'&&step==4">
            <div class="line" style="height: 10vh;margin:0 50px 0 auto;"><div class="border"></div></div>
            <div class="line" style="height:7px;margin:0 50px .6vh auto;"><div class="spot"></div></div>
            <div class="stepInfo">
              <div class="txt">{{ $t("step.index_04") }}</div>
            </div>        
            <div class="stepInfo">
              <span class="btn" @click="stepNext">{{ $t("step.got_it") }}</span>
            </div>
          </div>

        </div>

        
      </el-col>

      <div class="maskIn" v-if="isFirst==1&&status!=='login'&&step<=4"></div>

    </el-row>

    <el-row
      :style="{ background: bgPic, backgroundSize: ' 100% 100%' }"
      class="main"
    >
      <el-row
        class="msg-row"
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          flex-direction: column;
          top: 12.5%;
          left: 50%;
          transform: translate(-50%, 50%);
        "
         :class="{zindex_11:isFirst==1&&status!=='login'&&(step==1||step==3)}"
      >
        <img :src="url_logo" style="height: 6vw;width: auto;min-height: 60px;max-height: 124px;" :class="{hide50:isFirst==1&&status!=='login'&&(step==1||step==3)}" />
        
        <el-col style="" class="search-traynum" :class="{step_2:isFirst==1&&status!=='login'&&step==1,hide50:isFirst==1&&status!=='login'&&step==3}">
          <el-input
            :placeholder="$t('index.ts')"
            v-model="traynum"
            size="small"
            maxlength="20"
            @keyup.enter.native="search"
          ></el-input>
          <div class="search-btn" @click="search">
            <i class="el-icon-search">&nbsp;</i>
          </div>

          <div class="stepArea_1" v-if="isFirst==1&&status!=='login'&&step==1">
            <div class="line" style="height: 5vh;margin:0px 0 0 50%;"><div class="border"></div></div>
            <div class="line" style="height:7px;margin:0px 0 .6vh 50%;"><div class="spot"></div></div>
            <div class="stepInfo">
              <div class="txt">{{ $t("step.index_01") }}</div>
            </div>        
            <div class="stepInfo">
              <span class="btn" @click="stepNext">{{ $t("step.got_it") }}</span>
            </div>
          </div>

        </el-col>
        
        <div class="instructionVideo" @click="openVideo" @mouseover="over" @mouseout="out" :class="{step_3:isFirst==1&&status!=='login'&&step==3,hide50:isFirst==1&&status!=='login'&&step==1}">
          <span class="info">{{ $t("index.instructionVideo") }}</span><img class="img" :src="iconView" />
        </div>

        <div class="stepArea_3" v-if="isFirst==1&&status!=='login'&&step==3">
            <div class="line" style="height: 3vh;margin:0px 0 0 50%;"><div class="border"></div></div>
            <div class="line" style="height:7px;margin:0px 0 .6vh 50%;"><div class="spot"></div></div>
            <div class="stepInfo">
              <div class="txt">{{ $t("step.index_03") }}</div>
            </div>        
            <div class="stepInfo">
              <span class="btn" @click="stepNext">{{ $t("step.got_it") }}</span>
            </div>
          </div>

        
        

      </el-row>
      
    </el-row>
    <el-dialog
      :title="$t('allCable.choose')"
      :visible.sync="langDialogVisible"
      class="langDialogVisible"
      :before-close="handleClose_"
      :close-on-click-modal="false"
    >
      <template>
        <el-radio v-model="radioLangType" label="en" style="margin-right:40px;">English</el-radio>
        <el-radio v-model="radioLangType" label="es">Español</el-radio>
      </template> 
      <span slot="footer" class="dialog-footer" style="text-align: center;">        
        <el-button type="primary" size="small" style="padding: 9px 15px;margin-right:20px;" @click="changeLanguage()">{{ $t("form.sure") }}</el-button>
        <el-button type="primary" size="small" style="padding: 9px 15px;" plain @click="handleClose()">{{ $t("form.skip") }}</el-button>
      </span>
    </el-dialog>
    <!-- <div class="stepMask" v-if="isFirst==1&&status!=='login'&&step<=4"></div> -->
    <Footer></Footer>
  </div>
</template>

<script>
import api, { apikey } from "@/utils/api";
import Language from "@/components/Language";
import store from "@/store";
import { getSessionStorageData, setSessionStorage } from "@/utils/auth";
export default {
  components: {
    Language,
  },
  data() {
    return {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      // queryParam: {
      //   cablemodel: "AD100",
      // },
      // form: {},
      traynum: "",
      // commentsArr: [],
      // 登录状态
      status: "",
      username: "",
      tableData: [],
      isShow: 0,
      // url_left: "http://sumecem.dev.xweisoft.net/sumecemqr/96d6f2e7e1f705ab5e59c84a6dc009b21688711314.png",
      url_left: "",
      url_logo: require("@/assets/img/qrlogo.svg"),
      // 模型
      modelList: [],
      isMobile: false,
      bgPic: "",
      langtype:'',
      iconView_over:require('@/assets/img/view.svg'),
      iconView_out:require('@/assets/img/view.svg'),
      iconView:require('@/assets/img/view.svg'),
      step:1,
      isFirst:1, // 1 是第一次进来，2 非第一次进来
      langDialogVisible:!this.$Cookies.get("needShowLangSlt"),      
      radioLangType:'en'
    };
  },
  watch: {
    '$store.state.languageType' (newValue, oldValue) {
      this.langtype=newValue
    }
  },
  created() {
    this.hasMobile();
    this.getBg();
    this.isLogin();
    this.status = this.$Cookies.get("status");
    this.username = this.$Cookies.get("user_name");
    this.getModelList();
    let cur = getSessionStorageData("select_langue");
    if (!!cur) {
      this.langtype = cur
    }
    // 判断是否显示引导层
    this.isFirst = this.$Cookies.get("isFirstTypeOne") || 1;
  },
  mounted() {},
  destroyed() {},
  methods: {
    handleClose_() {
      this.langDialogVisible = false;
      this.$Cookies.set("needShowLangSlt",true);
    },
    handleClose() {
      this.langDialogVisible = false;
      this.$Cookies.set("needShowLangSlt",true);
      this.$Cookies.set("isFirstType1",2);
      this.$Cookies.set("isFirstType2",2);
      this.$Cookies.set("isFirstTypeOne",2);
      this.$Cookies.set("isFirstTypeTwo",2);
      this.$Cookies.set("isFirstTypeFore",2);
      this.$Cookies.set("isFirstTypeThr",2);
      this.isFirst = this.$Cookies.get("isFirstTypeOne") || 1;
    },
    changeLanguage() {
      this.$i18n.locale = this.radioLangType; // 设置给本地的i18n插件
      setSessionStorage("select_langue", this.radioLangType);
      store.commit('setLanguageType',this.radioLangType);
      this.$Cookies.set("needShowLangSlt",true);
      this.langDialogVisible = false;
    },
    stepNext(){
      this.step++;
      if(this.step>4){
        this.$Cookies.set("isFirstTypeOne",2);
        this.isFirst = 2;
      }
    },
    openVideo(){
      window.open('https://youtu.be/fV-GUOb8yyo');
    },
    over(){
      this.iconView = this.iconView_over
    },
    out(){
      this.iconView = this.iconView_out
    },
    hasMobile() {
      if (
        navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
      ) {
        // console.log('移动端');
        this.isMobile = true;
      }
      if (document.body.clientWidth < 620) {
        this.isMobile = true;
      }
      return this.isMobile;
    },
    // 判断是否是移动端
    isLogin() {
      // getCableMsg().then((res) => {
      //   console.log(res);
      // });
      this.axios({
        url: `/operate/login/islogin`,
        method: "get",
        // headers: this.headers,
      })
        .then((res) => {
          let obj = res.data;
          if (obj.error == 0) {
            this.$Cookies.set("user_name", obj.data.user_name, "7d");
            this.$Cookies.set("status", "login", "7d");
          } else {
            this.$Cookies.set("status", "logout");
          }
        })
        .catch((err) => {
          console.log(1);
        });
    },
    getBg() {
      // this.bgPic = 'url("http://sumecem.dev.xweisoft.net/sumecemqr/340c0cfdf4fee9969acc3b6b7357953.jpg") no-repeat'
      this.axios({
        url: `/base/initconfig`,
        method: "get",
        // headers: this.headers,
      })
        .then((res) => {
          let obj = res.data;
          console.log(obj);
          if (obj.error == 0) {
            if (this.isMobile) {
              this.bgPic = `url("${obj.data.mobile_login_backimg}") no-repeat `;
            } else {
              this.bgPic = `url("${obj.data.login_backimg}") no-repeat `;
            }
            this.url_left = obj.data.web_logo;
            this.$Cookies.set("logo", obj.data.web_logo);
            //  obj.data.mobile_login_backimg
            //  obj.data.login_backimg
          } else {
          }
        })
        .catch((err) => {
          console.log(1);
        });
    },
    getModelList() {
      this.axios({
        url: `/operate/cable/getModelList?apikey=${apikey}`,
        method: "get",
        // headers: this.headers,
      })
        .then((res) => {
          let obj = res.data;
          // console.log("res", obj.data);
          // let obj = res.data;
          if (obj.error == 0) {
            this.modelList = obj.data;
          } else if (obj.error == 400) {
            this.modelList = [];
          } else {
            console.log(obj.msg);
          }
        })
        .catch((err) => {
          console.log(1);
        });
    },
    handleModelCommand(val) {
      this.$router.push(`/model?modelid=${val}`);
      console.log(val);
    },
    // changeValue(value) {
    //   this.traynum = /^[0-9]*$/.test(parseInt(value))
    //     ? String(parseInt(value)).replace(".", "")
    //     : "";
    //   console.log(this.traynum);
    // },
    login() {
      this.$router.push("/login");
    },
    handleCommand(command) {
      // console.log(command);
      if (command == "a") {
        this.logout();
      } else {
        this.$router.push("./allCable");
      }
    },
    logout() {
      this.axios({
        url: `/operate/login/logout`,
        method: "get",
      })
        .then((res) => {
          console.log("退出", res);
          if (res.data.error == 0) {
            this.$Cookies.remove("user_name");
            this.$Cookies.remove("status");
            this.$Cookies.remove("traynum");
            this.status = "logout";
            this.traynum = "";
            window.location.href = "./";
            // this.$router.push("");
          }
        })
        .catch((err) => {
          console.log(1);
        });
    },
    search() {
      if (!!this.traynum) {
        this.$Cookies.remove("traynum");
        setTimeout(() => {
          this.$Cookies.set("traynum", this.traynum, "1d");
          // this.$router.push(`/installer?traynum=${this.traynum}`);
          let obj = {
            traynum: this.traynum,
            apikey: apikey,
            langtype:'en'
          };
          obj.langtype = this.langtype ? this.langtype : 'en'
          let formData = new FormData();
          for (let i in obj) {
            formData.append(i, obj[i]);
          }
          this.axios({
            url: `/operate/Cable/chantGetTrayInfo`,
            method: "post",
            data: formData,
            // headers: this.headers,
          })
            .then((res) => {
              let obj = res.data;
              if (obj.error == 0) {
                // 登录
                if (
                  !!this.$Cookies.get("status") &&
                  this.$Cookies.get("status") == "login"
                ) {
                  this.$router.push(`/table`);
                } else {
                  // 安装商
                  this.$router.push(`/installer`);
                }
              } else {
                this.msgError(obj.msg);
                return;
              }
              // console.log(obj);
            })
            .catch((err) => {
              // console.log(1);
            });
        }, 100);
      } else {
        this.msgError(this.$t('index.ts'));
        return;
      }
      // console.log(1);
    },
    showMore() {
      console.log(this.form.extend);
      this.isMore = 1;
    },
    downPdf(item) {
      window.location.href = `/operate/cable/file_down?file=${item}`;
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

body,
html {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
body,
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: #000; */
}
body {
  min-width: 320px !important;
  padding: 0;
  margin: 0;
  /* padding: 2rem 1.5rem !important; */
}
#searchPage {
  height: calc(100% - 40px);
}
.select-div {
  display: flex;
  align-items: center;
}
.head {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  background-color: #0778bc;
  color: #fff;
  position: fixed !important;
  top: 0;
  z-index: 11;
  /*  ; */
}
.logo {
  display: flex;
  align-items: center;
  margin-bottom: 3%;
  /* padding: 24px 48px 0; */
  /* justify-content: space-between; */
}
.left-img {
  width: 40%;
  height: 10%;
  text-align: left;
  flex-shrink: 0;
  /* width: 500px; */
}
/* .right-img {
  width: 30%;
  margin-left: auto;
} */

#searchPage .main {
  /* display: flex; */
  /* justify-content: center; */
  height: 100%;
  /* padding: 46px 10px 0; */
  /* transform: translateY(50%); */

  /* margin-top: 6vh; */
}
.left-tips {
  color: #fff;
  text-align: left;
  font-size: 2.3vw;
}

.internalNum {
  /* width: 30%; */
  /* position: absolute; */
  /* margin: 0 auto; */
  margin-top: 10%;
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  /* text-align: center; */
}

.internalNum .internalNum-input {
  display: flex;
  background-color: #fff;
  padding: 3px;
}

.internalNum span {
  color: #fff;
}

.internalNum .el-button {
  color: #fff;
  background-color: #0075c1;
  border: none;
}
.search-btn {
  color: #fff !important;
  /* background-color: #0075c1 !important; */
  /* border: none; */
  /* height: 60px; */
  background: #0678bc;
  border-radius: 0px 50px 50px 0px;
  /* line-height: 32px; */
  height: 6vh;
  line-height: 6vh;
  padding: 0 5%;
  font-size: 1.8vh;
  cursor: pointer;
}
.internalNum .el-button--mini,
.el-button--mini.is-round {
  /* padding: 10px; */
}

.internalNum-tips {
  margin-top: 20px;
  font-size: 2.3vw;
}
.left-img img {
  /* width: 60%; */
}
.msg-row {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.el-button:focus,
.el-button:hover {
  border-color: none;
}
/* .el-image img {
  width: 10px;
  height: 35px;
  margin-right: 10px;
  vertical-align: middle;
} */
.el-input.el-input--small {
  /* width: 40%; */
  margin: auto;
  /* margin-right: 15px; */
}
/deep/.msg-row .el-input__inner {
  height: 6vh;
  background-color: #fff;
  /* color: #fff; */
  border-radius: 50px 0 0 50px;
  /* height: 60px; */
  border: 1px solid #0678bc;
  font-size: 1.8vh;
  padding-left: 4%;
  /* border-radius: 30px; */
}
.head-box {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  position: relative;
}
.about {
  border-left: 1px solid #ccc;
  display: flex;
  justify-content: space-around;
  font-size: 18px;
  position: relative;
}
.el-dropdown {
}
/* .about>span,
.about>div{
  margin-left:2rem;
} */
.login {
  display: inline-block;
  height: 5vh;
  line-height: 5vh;
  background-color: #fff;
  border-radius: 5vh;
  color: #0075c1;
  padding: 0 20px;
  font-size: 1.8vh;
}
/deep/.el-input--small .el-input__icon {
  height: 6vh;
  line-height: 6vh;
  font-size: 1.8vh;
}
.login,
.logout {
  margin-left: auto;
}
.el-dropdown {
  color: #fff !important;
  font-size: 16px;
}
.mobile-div .el-dropdown {
  color: #fff !important;
}
.logout-icon .el-icon-user {
  padding: 7px;
  border-radius: 50%;
  background-color: #fff;
  color: #0778bc;
  margin-right: 10px;
}
/* 左上角的logo */
.img-box {
  height: 6vh;
  width: auto;
  display: inline-block;
  /* max-width: 100%; */
}
/* 中间的logo */
.main-logo {
  margin-top: 25vh;
}
.search-traynum {
  display: flex;
  margin-top: 45px;
  width: 40vw;
  position: relative;
}
.cursor {
  cursor: pointer;
}
.el-dropdown-menu__item {
  padding: 0 15px !important;
  font-family: Helvetica, helvetica, Arial, sans-serif;
}
/* @media only screen and (max-width: 820px) {
  .img-box .el-image {
    height: 4vh;
  }

  #searchPage .main {
    height: 90vh;
    margin-top: 5vh;
  }
  .search-traynum {
    width: 45vw;
  }
} */
.el-icon-search {
  font-size: 1.8vh;
}
@media only screen and (max-width: 820px) {
  .img-box {
    height: 4vh;
    scale: 1.18;
  }

  #searchPage .main {
    background: url("../assets/img/phone-bg.png") no-repeat;
    background-size: 125% 100%;
    background-position: center;
  }
  .search-traynum {
    width: 60vw;
  }
  /deep/.msg-row .el-input__inner {
    height: 40px;
    font-size: 14px;
  }
  .search-btn {
    height: 40px;
    line-height: 40px;
  }
  .login {
    height: 4vh;
    line-height: 4vh;
  }
  .about,
  .el-dropdown {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  /* #searchPage .main {
    background: url("../assets/img/phone-bg.png") no-repeat;
    background-size: 100% 100%;
  } */
  .search-traynum {
    width: 80vw;
  }
  .about > span,
  .logout-icon {
    display: none;
  }
  .logout .el-dropdown-link {
    display: flex;
  }
  .about {
    position: relative;
    top: 0;
  }

  #product-drop {
    position: absolute;
    top: 4vh;
    right: 0;
    background: #ccc;
  }
  .login {
    height: 3vh;
    line-height: 3vh;
  }
}

#searchPage .instructionVideo{
    margin-top: 45px;    
    cursor: pointer;
    background-color: rgba(0,0,0,.5);
    border-radius: 20px;
    padding: 0 6px 0 10px;
  }
#searchPage .instructionVideo .info{
  display: inline-block;
  height: 24px;
  line-height: 24px;
  float: left;  
  color: #ffffff;
  margin-right: 6px;
  font-size: 14px;
}
#searchPage .instructionVideo .img{
  margin:2px auto 0 auto;width:20px;height:20px;
}
.instructionVideo:hover{  
  opacity: 0.8;
  background-color: rgba(0,0,0,1);
}

/* 引导层 */
/* .stepMask{
  width:100%;height: 100%;left:0;top:0;position: fixed;background-color: rgba(0,0,0,0.65);z-index: 10;
} */
#searchPage .maskIn{
  width:100%;height: 100%;left:0;top:0;position: fixed;background-color: rgba(0,0,0,0.65);z-index: 15;
}
#searchPage .step_1,#searchPage .step_2,#searchPage .step_3,#searchPage .step_4{
  z-index: 16;
  box-shadow: 0 0 8px #fff;  
  border: 1px dashed #ffffff;
  padding: 5px 10px;
}
#searchPage .step_2{
  border-radius: 50px;
}
#searchPage .step_3{
  padding:0 6px 0 10px;
}
#searchPage .step_4{
  border-radius: 5vh;
  padding: 0 20px;
  font-size: 1.8vh;
  border: 0;
}
#searchPage .zindex_11{
  z-index: 11;
}
#searchPage .hide50{
  opacity: 0.4;
}
#searchPage .stepArea_1,#searchPage .stepArea_2,#searchPage .stepArea_3,#searchPage .stepArea_4{
  width:100%;height: 100%;left:0;top:100%;position:absolute;z-index: 116;  
}

#searchPage .line{
  width: 7px;    
  display: flex;    
}

#searchPage .line .border{
  width: 1px;
  height: 100%;
  background-color: #ffffff;
  margin: 0 auto;
}
#searchPage .line .spot{
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color:#ffffff;
}

#searchPage .stepInfo{
  display: flex;
  width: 70vw;
  text-align: left;    
}

#searchPage .stepInfo .txt{
  color: #ffffff;
  font-size: .95rem;
  display: block;
  width: 100%;
  word-break: keep-all;
  line-height: 1.5;
  word-wrap: break-word;
}
#searchPage .stepInfo .btn{
  border-radius: 20px;
  border: 1px solid #ffffff;
  color: #ffffff;
  display: block;
  margin: 5vh auto 0 0;
  font-size: 1rem;
  cursor: pointer;
  padding: 6px 40px;
  background-color: rgba(0,0,0,0.4);
}
#searchPage .stepArea_1{
  top:105%;
}
#searchPage .stepArea_1 .stepInfo{
  width: 40vw;
  text-align: center;  
}
#searchPage .stepArea_1 .stepInfo .btn{
  margin: 5vh auto 0 auto;
}

#searchPage .stepArea_3{
  top:101%;  
}
#searchPage .stepArea_3 .stepInfo{
  width: 40vw;
  text-align: center;    
}
#searchPage .stepArea_3 .stepInfo .btn{
  margin: 5vh auto 0 auto;
}

#searchPage .stepArea_4{
  top:105%;  
}
#searchPage .stepArea_4 .stepInfo{
  width: 40vw;
  text-align: right;
  float: right;    
}
#searchPage .stepArea_4 .stepInfo .btn{
  margin: 5vh 0 0 auto;
}



.langDialogVisible /deep/ .el-dialog{
  width: 360px!important;
}
#searchPage /deep/ .el-dialog .el-dialog__footer{
  text-align: center;
}
@media only screen and (max-width: 767px) {
  #searchPage  .langDialogVisible /deep/ .el-dialog{
    width: 80vw!important;
  }
  #searchPage .stepArea_2 .stepInfo{
    width: 80vw;margin-left:-20vw;
  }
  #searchPage .stepArea_1 .stepInfo{
    width: 80vw;
  }
  #searchPage .stepArea_3 .stepInfo{
    width: 80vw;
  }
  #searchPage .stepArea_4 .stepInfo{
    width: 80vw;
  }
}
</style>